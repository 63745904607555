  // Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "price", "quantity", "discount", "total" ]

  connect() {
    //console.log('Hello, Stimulus!')
  }

  update_total(event) {
    const price = this.priceTarget.value
    const qty = this.quantityTarget.value
    const discount = this.discountTarget.value
    let total = price*qty
    if (discount > 0) {
      total = total * (1-discount/100)
    }
    this.totalTarget.value = Number(total).toFixed(2)
  }

  // async update_contact_data(event) {
  //   const contact_id = event.target.value
  //   const res = await fetch(`/contacts/${contact_id}.json`)
  //   const contact = await res.json()
  //   this.contactTitleTarget.value = contact.data.attributes.title
  //   this.contactEmploymentTarget.value = contact.data.attributes.working_place
  //   this.contactVehicleTarget.value = contact.data.attributes.vehicle_id
  // }

  // load_form(event) {
  //   const id = this.data.get("id")
  //   fetch('/travel-orders/'+id+'/render_partial?partial=form')
  //     .then(response => response.text())
  //     .then(html => {
  //       this.element.innerHTML = html
  //     })
  // }
}
