  // Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = ["modalNew",
                    "search", "companySearchDropdown", "results", "selectedId", "selectedName",
                    "newCompanyName", "newCompanyVat", "newCompanyError"
                  ]

  connect() {
  }

  hide_modal_new(event) {
    event.preventDefault()
    this.modalNewTarget.classList.remove("is-active")
  }

  show_modal_new(event) {
    event.preventDefault()
    this.modalNewTarget.classList.add("is-active")
  }

  async search(event) {
    this.resultsTarget.innerHTML = ''
    const value = this.searchTarget.value
    if (value.length < 1) {
      this.companySearchDropdownTarget.classList.remove("is-active")
      return
    }
    const res = await fetch(`/companies?query=${value}`, {
                           headers: { accept: 'application/json'}
                      })

    const data = await res.json()
    let companyHTML = ""
    data.data.forEach( company => {
      companyHTML += this.companyTemplate(company.attributes)
    })

    this.companySearchDropdownTarget.classList.add("is-active")
    this.resultsTarget.insertAdjacentHTML('afterbegin', companyHTML)
  }

  async create_company(event) {
    event.preventDefault()
    let data = new FormData()
    data.append("company[name]", this.newCompanyNameTarget.value)
    data.append("company[vat]", this.newCompanyVatTarget.value)
    this.newCompanyErrorTarget.innerHTML = ""

    Rails.ajax({
      url: `/companies`,
      type: "post",
      data: data,
      dataType: 'json',
      success: (data) => {
        this.modalNewTarget.classList.remove("is-active")
        this.selectedIdTarget.value = data.data.attributes.id
        this.searchTarget.value = data.data.attributes.name
        this.companySearchDropdownTarget.classList.remove("is-active")
      },
      error: (data) => {
        let errors = ""
        data.forEach( e => {
          errors += `<p>${e}</p>`
        })
        this.newCompanyErrorTarget.insertAdjacentHTML('afterbegin', errors)
      }
    })
  }

  selected(event) {
    event.preventDefault()
    this.companySearchDropdownTarget.classList.remove("is-active")
    this.selectedIdTarget.value = event.target.dataset.companyId
    this.searchTarget.value = event.target.dataset.companyName
  }

  // getMetaValue(name) {
  //   const element = document.head.querySelector(`meta[name="${name}"]`);
  //   return element.getAttribute("content");
  // }

  companyTemplate(company) {
    return `<a class="dropdown-item" data-action="click->company#selected" data-company-id="${company.id}", data-company-name="${company.name}">
        ${company.vat} ${company.name}
        <br />
        <small>${company.address || ""}</small>
      </a>
    `
  }

  //
  // companyTemplate(company) {
  //   return `<tr>
  //     <td>${company.vat}</td>
  //     <td>
  //       <a data-action="click->company#selected" data-company-id="${company.id}", data-company-name="${company.vat} - ${company.name}">${company.name}</a>
  //       <br/>
  //       <small><i>${company.address}</i></small>
  //     </td>
  //   </tr> `
  // }


  // async update_contact_data(event) {
  //   const contact_id = event.target.value
  //   const res = await fetch(`/contacts/${contact_id}.json`)
  //   const contact = await res.json()
  //   this.contactTitleTarget.value = contact.data.attributes.title
  //   this.contactEmploymentTarget.value = contact.data.attributes.working_place
  //   this.contactVehicleTarget.value = contact.data.attributes.vehicle_id
  // }

  // load_form(event) {
  //   const id = this.data.get("id")
  //   fetch('/travel-orders/'+id+'/render_partial?partial=form')
  //     .then(response => response.text())
  //     .then(html => {
  //       this.element.innerHTML = html
  //     })
  // }
}
