  // Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = ["modalNew",
                    "search", "productSearchDropdown", "results", "selectedId", "selectedName",
                    "newProductName", "newProductVat", "newProductError"
                  ]

  connect() {
  }

  show_modal_new(event) {
    event.preventDefault()
    this.modalNewTarget.classList.add("is-active")
  }

  hide_modal_new(event) {
    event.preventDefault()
    this.modalNewTarget.classList.remove("is-active")
  }

  async search(event) {
    this.resultsTarget.innerHTML = ''
    const value = this.searchTarget.value
    if (value.length < 1) {
      this.productSearchDropdownTarget.classList.remove("is-active")
      return
    }
    const res = await fetch(`/products?name=${value}`, {
                           headers: { accept: 'application/json'}
                      })

    const data = await res.json()
    let productHTML = ""
    data.data.forEach( product => {
      productHTML += this.productTemplate(product.attributes)
    })

    this.productSearchDropdownTarget.classList.add("is-active")
    this.resultsTarget.insertAdjacentHTML('afterbegin', productHTML)
  }

  async create_product(event) {
    event.preventDefault()
    let data = new FormData()
    data.append("product[name]", this.newProductNameTarget.value)
    data.append("product[tax_rate_id]", this.newProductVatTarget.value)
    this.newProductErrorTarget.innerHTML = ""

    Rails.ajax({
      url: `/products`,
      type: "post",
      data: data,
      dataType: 'json',
      success: (data) => {
        this.modalNewTarget.classList.remove("is-active")
        this.selectedIdTarget.value = data.data.attributes.id
        this.searchTarget.value = data.data.attributes.name
      },
      error: (data) => {
        let errors = ""
        data.forEach( e => {
          errors += `<p>${e}</p>`
        })
        this.newProductErrorTarget.insertAdjacentHTML('afterbegin', errors)
      }
    })
  }

  selected(event) {
    event.preventDefault()
    this.productSearchDropdownTarget.classList.remove("is-active")
    this.searchTarget.value = event.target.dataset.productName
    this.selectedIdTarget.value = event.target.dataset.productId
    this.selectedIdTarget.setAttribute("data-product-price", event.target.dataset.productPrice)

    const newEvent = new Event('change')
    this.selectedIdTarget.dispatchEvent(newEvent)
  }

  productTemplate(product) {
    return `<a class="dropdown-item" data-action="click->product#selected" data-product-id="${product.id}", data-product-name="${product.name}", data-product-price="${product.sell_price}">
        ${product.code} ${product.name}
      </a>
    `
  }


  // async update_contact_data(event) {
  //   const contact_id = event.target.value
  //   const res = await fetch(`/contacts/${contact_id}.json`)
  //   const contact = await res.json()
  //   this.contactTitleTarget.value = contact.data.attributes.title
  //   this.contactEmploymentTarget.value = contact.data.attributes.working_place
  //   this.contactVehicleTarget.value = contact.data.attributes.vehicle_id
  // }

  // load_form(event) {
  //   const id = this.data.get("id")
  //   fetch('/travel-orders/'+id+'/render_partial?partial=form')
  //     .then(response => response.text())
  //     .then(html => {
  //       this.element.innerHTML = html
  //     })
  // }
}
