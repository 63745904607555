  // Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "companyId", "invoiceId" ]

  connect() {
    console.log('Hello, Stimulus!')
  }

  show_modal(event) {
    event.preventDefault()
    this.modalTarget.classList.add("is-active")
    this.companyIdTarget.value = event.target.dataset.companyId
    this.invoiceIdTarget.value = event.target.dataset.invoiceId
  }
  hide_modal(event) {
    event.preventDefault()
    this.modalTarget.classList.remove("is-active")
  }


  // async search(event) {
  //   const value = this.searchTarget.value

  //   const res = await fetch(`/companies?query=${value}`, {
  //                          headers: { accept: 'application/json'}
  //                     })

  //   const data = await res.json()
  //   let companyHTML = ""
  //   data.data.forEach(company => {
  //     companyHTML += this.companyTemplate(company.attributes)
  //   })
  //   this.resultsTarget.innerHTML = companyHTML
  // }

  // selected(event) {
  //   event.preventDefault()
  //   this.modalTarget.classList.remove("is-active")
  //   this.selectedIdTarget.value = event.target.dataset.companyId
  //   this.selectedNameTarget.value = event.target.dataset.companyName
  // }

  // getMetaValue(name) {
  //   const element = document.head.querySelector(`meta[name="${name}"]`);
  //   return element.getAttribute("content");
  // }

  // companyTemplate(company) {
  //   return `<tr>
  //     <td>${company.vat}</td>
  //     <td>
  //       <a data-action="click->company#selected" data-company-id="${company.id}", data-company-name="${company.vat} - ${company.name}">${company.name}</a>
  //       <br/>
  //       <small><i>${company.address}</i></small>
  //     </td>
  //   </tr> `
  // }


  // async update_contact_data(event) {
  //   const contact_id = event.target.value
  //   const res = await fetch(`/contacts/${contact_id}.json`)
  //   const contact = await res.json()
  //   this.contactTitleTarget.value = contact.data.attributes.title
  //   this.contactEmploymentTarget.value = contact.data.attributes.working_place
  //   this.contactVehicleTarget.value = contact.data.attributes.vehicle_id
  // }

  // load_form(event) {
  //   const id = this.data.get("id")
  //   fetch('/travel-orders/'+id+'/render_partial?partial=form')
  //     .then(response => response.text())
  //     .then(html => {
  //       this.element.innerHTML = html
  //     })
  // }
}
