  // Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "search", "results", "selectedId", "selectedName" ]

  connect() {
    //console.log('Hello, Stimulus!')
  }

  show_modal(event) {
    event.preventDefault()
    this.modalTarget.classList.add("is-active")
  }

  async search(event) {
    const value = this.searchTarget.value

    const res = await fetch(`/contacts?query=${value}`, {
                           headers: { accept: 'application/json'}
                      })

    const data = await res.json()
    let contactHTML = ""
    data.data.forEach(contact => {
      contactHTML += this.contactTemplate(contact.attributes)
    })
    this.resultsTarget.innerHTML = contactHTML
  }

  selected(event) {
    event.preventDefault()
    this.modalTarget.classList.remove("is-active")
    this.selectedIdTarget.value = event.target.dataset.contactId
    this.selectedNameTarget.value = event.target.dataset.contactName
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }

  contactTemplate(contact) {
    return `<tr>
      <td>${contact.goverment_id}</td>
      <td>
        <a data-action="click->contact#selected" data-contact-id="${contact.id}", data-contact-name="${contact.goverment_id} - ${contact.firstname} ${contact.lastname}">${contact.firstname} ${contact.lastname}</a>
      </td>
    </tr> `
  }


  // async update_contact_data(event) {
  //   const contact_id = event.target.value
  //   const res = await fetch(`/contacts/${contact_id}.json`)
  //   const contact = await res.json()
  //   this.contactTitleTarget.value = contact.data.attributes.title
  //   this.contactEmploymentTarget.value = contact.data.attributes.working_place
  //   this.contactVehicleTarget.value = contact.data.attributes.vehicle_id
  // }

  // load_form(event) {
  //   const id = this.data.get("id")
  //   fetch('/travel-orders/'+id+'/render_partial?partial=form')
  //     .then(response => response.text())
  //     .then(html => {
  //       this.element.innerHTML = html
  //     })
  // }
}
