  // Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "price", "quantity", "discount", "total", "pid" ]

  connect() {
    //console.log('Hello, Stimulus!')
  }

  update_total(event) {
    const price = this.priceTarget.value
    const qty = this.quantityTarget.value
    const discount = this.discountTarget.value
    let total = price*qty
    if (discount > 0) {
      total = total * (1-discount/100)
    }
    this.totalTarget.value = Number(total).toFixed(2)
  }

  product_updated(event) {
    const price = event.target.dataset.productPrice
    const qty = 1
    const discount = 0
    this.priceTarget.value = price
    this.quantityTarget.value = qty
    this.discountTarget.value = discount
    this.totalTarget.value = Number(price*qty).toFixed(2)
  }

}
